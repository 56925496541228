import axiosServices from './request';

const UserApi = axiosServices('/usercenter/v1.0');

export default {
  // 登录
  login: UserApi('post', 'user/login'),

  // 获取用户信息
  getUserInfo: UserApi('get', 'user/getInfo'),

  // 获取渠道id 默认取第一个
  getChannelId: UserApi('get', 'channel/getChannelListByToken')
};
