import React from 'react';
import Loadable from 'react-loadable';
import Message from '@/components/Message';

import { createRouter } from '@/utils/router';
import * as Cookie from '@/utils/cookie';
import PAGE from '@/constants/page';
import Loading from '@/components/Loading';
import userapi from '@/api/userapi';
import spider from '@/business/spider';

import App from './App';

export default createRouter({
  getComponent: () => App,
  onEnter: async (nextState, history, cb) => {
    const { location, match, query, query: { token, redirectURL = '', fromAdviser }} = nextState;
    const cookie = Cookie.getCookie();
    const isMatch = match && match.isExact;
    spider.init();
    // 已登录
    if(query.name && query.pwd) {
      userapi.login({
        username: decodeURIComponent(query.name),
        password: query.pwd
      })
        .then(res => {
          Cookie.saveCookie(res.token);
          Message.success('登录成功');
        })
        .catch(() => {
          history.replace(PAGE.LOGIN);
        })
        .finally(() => {
          cb();
        });
    } else if(token && (redirectURL || fromAdviser)) {
      let decodeToken = decodeURIComponent(escape(token));
      Cookie.saveCookie(decodeToken);
      // 兼容编辑端内嵌打开
      if(!fromAdviser) {
        history.replace(PAGE.HOME);
      }
      cb();
    } else if(cookie) {
      // index route
      if(isMatch) {
        history.replace(PAGE.HOME);
      }
      cb();
    } else {    // 未登录
      if(location.pathname.startsWith('/main') || isMatch) {
        history.replace(PAGE.LOGIN);
      }
      cb();
    }

  },
  getChildRoutes: () => [
    {
      path: '/login',
      component: Loadable({
        loader: () => import('./login'),
        loading: () => <Loading />
      })
    },
    {
      path: '/shop',
      component: Loadable({
        loader: () => import('./pages'),
        loading: () => <Loading />
      })
    },
    {
      component: Loadable({
        loader: () => import('./404'),
        loading: () => <Loading />
      })
    }
  ]
});
