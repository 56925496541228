
export const isDevelopment = process.env.NODE_ENV !== 'production';

export const BASE_API_ROOT = window.location.origin + (isDevelopment ? '/api' : '');

const hostIP = isDevelopment ? 'localhost' : window.location.hostname; // STEP2
const publicDomain = isDevelopment
  ? 'raysgo.com'
  : hostIP.substring(hostIP.indexOf('.') + 1);

// 系统角色
const systemCode = 'APP_SHOP';

const ENV_ENUM = {
  TEST: 'raysgo.com',
  UAT: 'raysyun.com',
  PRO: '5rs.me'
};

const HOST_NAME = window.location.hostname || '';
const DOMAIN = HOST_NAME.slice(HOST_NAME.indexOf('.') + 1);

let ENV = 'TEST';
Object.keys(ENV_ENUM).forEach(key => {
  if(ENV_ENUM[key] === DOMAIN) {
    ENV = key;
  }
});

// 系统 ID
const systemId = '1';

const config = {
  isDevelopment,
  systemCode,
  publicDomain,
  industryCaseId: systemId,
  systemCodeText: '运营应用商城',
  COOKIE: `${systemCode}_token`,
  PRODUCTION_COOKIE: `${systemCode}_production_id`,
  iconfontURL: '//at.alicdn.com/t/font_1542801_kdyfn2gn11i.js',     // 字体图标库链接
  env: ENV
};

export default config;
