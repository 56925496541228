/**
 *  Date    : 2019/11/7
 *  Author  : CastileMan
 *  Declare : user
 */
import Immutable from 'seamless-immutable';

import userApi from '@/api/userapi';
import Utils from '@/utils/utils';

import setPermission from './menus';
import * as Cookie from '@/utils/cookie';
import Message from '@/components/Message';

export default {
  namespace: 'user',
  state: Immutable({
    userInfo: {
      permissionMenus: [],
      flatPermissionMenus: []
    },
    partyNameList: [],
    memberAccountList: {},
    roleList: [],
    labelList: [],
    memberBaseInfo: {},
    industryCaseInfo: {},
    channelIdInfo: {}
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    // 获取用户信息
    getUserInfo() {
      return {
        type: 'user/update/updateUserInfo',
        field: 'userInfo',
        promise: userApi.getUserInfo()
          .then(data => {
            const { permissionVOList, partyId, ...userInfo } = data;
            //订单管理
            Cookie.saveCookie(`123adivder=${data.partyId}asddscjs`, 'userInfo');
            return Object.assign(data, {
              permissionMenus: setPermission(userInfo),
              flatPermissionMenus: Utils.flatMap(setPermission(userInfo))
            });
          })
      };
    },
    // 登录
    login(payload) {
      return {
        promise: userApi.login(payload)
      };
    },
    // 退出
    logout() {
      return {
        promise: new Promise(() => {
          // 直接退出系统
          Cookie.saveCookie('');  // 清除token
          Message.success('注销成功');
          window.location.href = '/login';
        })
      };
    },
    // 渠道id
    getChannelId() {
      return {
        type: 'user/update/updateChannelIdInfo',
        field: 'channelIdInfo',
        promise: userApi.getChannelId()
          .then(data => {
            if(data.length) {
              return data[0];
            }
          })
      };
    }
  }
};
